import "./App.css";
import FooterSection from "./sections/Footer/index";
import SuccesCasesSection from "./sections/SuccesCases";
import { dataSuccesCases } from "./core/utils/contants/index";
import HeroSection from "./sections/Hero";
import HireSection from "./sections/Hire";
import ContactSection from "./sections/Contact";
import Navbar from "./components/Navbar/Navbar";

function App() {
  return (
    <>
 
      <Navbar />
      <HeroSection id="Hero" />
      <SuccesCasesSection SuccesCases={dataSuccesCases} />
      <HireSection />
      <ContactSection id="Contact" />
      <FooterSection />
    </>
  );
}

export default App;
