import { useState } from "react";
import { Card, Dialog, Grid, Typography } from "@mui/material";
import PlaceholderVideo from "../../PlaceholderVideo/PlaceholderVideo";
import ModalTechnologies from "../ModalTechnologies/ModalTechnologies";

export interface CardProps {
  description: string;
  services: string[];
  technologies: any;
  video: string;
  title: string;
  index: number;
}

export default function CardCases({ ...CardProps }: CardProps) {
  const [open, setOpen] = useState(false);
  // @ts-ignore
  const [selectedIndustry, setSelectedIndustry] = useState(null);

  const handleClickOpen = (industry: any) => {
    setSelectedIndustry(industry);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedIndustry(null);
  };

  return (
    <>
      <Card
        key={CardProps.index}
        className="no-border"
        sx={{
          mx: { xs: "2%", sm: "7%", md: "10%" },
          my: { xs: "4%", sm: "1px" },
          px: { xs: "2%", sm: "3%", md: "5%" },
          py: { xs: "2%", sm: "2%", md: "2%" },
          backgroundColor: "rgba(0, 0, 0, 0)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid
          container
          sx={{
            mx: { xs: "5%", sm: "7%", md: "10%" },
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={() => handleClickOpen(CardProps.title)}
        >
          <Grid
            item
            sx={{
              borderRadius: 5,
              borderBottomLeftRadius: 0,
              borderTopRightRadius: 0,
              borderColor: "#A0730C",
              borderWidth: 2,
              borderStyle: "solid",
              backgroundColor: "#000",
              padding: { xs: "8px", sm: "12px", md: "16px" },
              marginBottom: { xs: "15px", sm: "12px", md: "16px" },
              width: { xs: "100%", sm: "350px", md: "350px" },
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Typography
              component="h2"
              fontFamily={"Raleway"}
              color={"white"}
              sx={{
                fontWeight: 600,
                whiteSpace: "nowrap",
                fontSize: { xs: 20, sm: 28 },
                maxWidth: 260,
                maxHeight: 77,
              }}
            >
              {CardProps.title}
            </Typography>
          </Grid>

          <PlaceholderVideo video={CardProps.video} />
        </Grid>
      </Card>

      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-paper": {
            backgroundColor: "transparent",
            borderRadius: "15px",
            overflow: "hidden",
            width: "85%",
            maxWidth: { xs: "95%", sm: "45%", md: "35%", lg: "25%", xl: "20%" },
            position: "relative",
            maxHeight: { xs: "100vh", sm: "400px" },
            minHeight: 500,
            boxShadow: "none",
            border: "solid 2px #A0730C",
          },
        }}
      >
        <ModalTechnologies
          onClick={handleClose}
          title={CardProps.title}
          description={CardProps.description}
          services={CardProps.services}
          technologies={CardProps.technologies}
        />
      </Dialog>
    </>
  );
}
