import { Button } from '@mui/material';
import React from 'react';

export interface ActionButtonProps {
	label: string;
	color: string;
	textColor: string;
	hover: string;
	theme?: string;
	icon?: React.ElementType;
	onClick?: () => void;
	sx?: {};
}

export default function ActionButton({
	icon: Icon,
	label,
	textColor,
	color,
	hover,
	onClick,
	sx,
}: ActionButtonProps) {
	return (
		<Button
			startIcon={Icon ? <Icon sx={{ mb: 0.5 }} /> : null}
			onClick={onClick}
			sx={{
				my: 1,
				backgroundColor: color,
				'&:hover': {
					backgroundColor: hover,
					color: color,
				},
				borderRadius: '10px',
				color: textColor,
				minWidth: { xs: '90vw', sm: '30vw', md: '20vw' },
				maxWidth: { xs: '220px', sm: '300px', md: '300px' },
				textTransform: 'capitalize',
				border: `2px solid ${color}`,
				...sx,
			}}
		>
			{label}
		</Button>
	);
}
