import { createTheme } from "@mui/material/styles";

declare module "@mui/material/styles/createPalette" {
  interface Palette {
    gradient: {
      primary: string;
      secondary: string;
    };
  }
  interface PaletteOptions {
    gradient?: {
      primary: string;
      secondary: string;
    };
  }
}

const theme = createTheme({
  typography: {
    fontFamily: "Raleway, Arial, sans-serif",
  },
  palette: {
    primary: {
      main: "#A0730C",
    },
    secondary: {
      main: "#D1BB8A",
    },
    background: {
      default: "#000000",
      paper: "#FFFFFF",
    },
    text: {
      primary: "#FFFFFF",
      secondary: "#E2C98F",
    },
    warning: {
      main: "#E8FE74",
    },
    gradient: {
      primary: "linear-gradient(0deg, #A0730C 0%, #E2C98F 47%, #E8FE74 100%)",
      secondary: "linear-gradient(0deg, #E8FE74 0%, #000000 100%)",
    },
  },
});

export default theme;
