import { Button, Box } from "@mui/material";
import React from "react";

export interface VisitButtonProps {
  label: string;
  color: string;
  textColor: string;
  hover: string;
  gradientBorder: string;
  theme?: string;
  icon?: React.ElementType;
  onClick: () => void;
  sx?: {};
}

export default function VisitButton({
  icon: Icon,
  label,
  color,
  textColor,
  hover,
  gradientBorder,
  onClick,
  sx,
}: VisitButtonProps) {
  return (
    <Box
      sx={{
        display: "inline-block",
        background: gradientBorder,
        padding: "4px", 
        borderRadius: "50%", 
      }}
    >
      <Button
        onClick={onClick}
        sx={{
          backgroundColor: color,
          borderRadius: "50%", 
          color: textColor,
          minWidth: { xs: "200px", sm: "220px" },
          minHeight: { xs: "200px", sm: "220px" },
          textTransform: "capitalize",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          border: "none",
          fontSize: { xs: 18, sm: 20 },
          fontWeight: 600,
          "&:hover": {
            backgroundColor: hover,
            color: "#000",
          },
          ...sx,
        }}
      >
        <Box sx={{ textAlign: "center" }}>
          {label.split(" ")[0]} <br></br>
          {label.split(" ")[1]}
        </Box>
        {Icon && <Icon sx={{ mt: 0.5 }} />}{" "}
      </Button>
    </Box>
  );
}
