import { AddTask } from "@mui/icons-material";
import { Box, Card, Grid, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export interface Technology {
  technology: string;
  icon: string;
  index: number;
}
export interface CardProps {
  onClick: () => void;
  title: string;
  description: string;
  services: string[];
  technologies: Technology[];
}export default function ModalTechnologies({ ...CardProps }: CardProps) {
  return (
    <>
      <IconButton
        onClick={CardProps.onClick}
        sx={{
          position: "absolute",
          top: "1%",
          right: "1.5%",
          zIndex: "9999",
          color: "white",
          backgroundColor: "grey",
          opacity: 0.5,
          borderRadius: "50%",
          padding: "2px",
          "&:hover": {
            backgroundColor: "greydark",
            opacity: 1,
          },
        }}
      >
        <CloseIcon sx={{ fontSize: 25 }} />
      </IconButton>
      <Card
        className="no-border"
        style={{
          backgroundColor: "black",
          border: "2px solid white",
          position: "relative",
          minHeight: 550,
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column", // Mantiene flexbox en el Card
        }}
      >
        <Grid
          container
          sx={{
            backgroundColor: "#000",
            padding: { xs: "8px", sm: "12px", md: "16px" },
            margin: { xs: "8px", sm: "12px", md: "16px" },
            flexGrow: { xs: 0.5, sm: 0.2, md: 0.2 },// Permite que este Grid ocupe el espacio sobrante
          }}
        >
          <Grid item xs={12}>
            <Typography
              fontFamily={"Raleway"}
              color={"white"}
              sx={{
                textAlign: "center",
                fontWeight: 700,
                whiteSpace: "normal",
                fontSize: 25,
                mt:2
              }}
            >
              {CardProps.title}
            </Typography>
         {/*    <Box sx={{ mt: 0.7, mx: 1 }}>
              <Typography
                component="h2"
                fontFamily={"Raleway"}
                color={"white"}
                sx={{
                  fontWeight: 300,
                  whiteSpace: "normal",
                  fontSize: 16,
                }}
              >
                {CardProps.description}
              </Typography>
            </Box> */}
          </Grid>

          <Grid item xs={12} sx={{ mt: 1,   alignItems:"center",
                textAlign:"center" }}>
            {CardProps.services.map((service, index) => (
              <Box
                key={index}
                display="flex"
                alignItems="center"
                textAlign="center"
                sx={{ mt: 1 }}
              >
                <AddTask sx={{ mr: 1, color: "#FFFFFF" }} />
                <Typography
                  variant="body2"
                  color="#FFFFFF"
                  sx={{
                    fontWeight: 300,
                    whiteSpace: "normal",
                    fontSize: 16,
                  }}
                >
                  {service}
                </Typography>
              </Box>
            ))}
          </Grid>
        </Grid>

        {/* Footer con spacing dinámico */}
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{
            padding: { xs: "8px", sm: "10px", md: "10px" },
            mt: {
              xs: "auto", // En móviles deja espacio automáticamente
              sm: 14,
              md: 12, // En pantallas grandes, deja más espacio arriba
            },
            bottom: 1,
          }}
        >
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Typography
                fontFamily={"Raleway"}
                color={"white"}
                sx={{
                  fontWeight: 600,
                  whiteSpace: "normal",
                  fontSize: 18,
                  mb: 2,
                }}
              >
                Technologies
              </Typography>
              <Grid
                container
                spacing={2}
                justifyContent="center"
                alignItems="center"
              >
                {CardProps.technologies.map((tech, index) => (
                  <Grid
                    item
                    xs={4}
                    sm={3}
                    md={2}
                    key={index}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Box
                      component="img"
                      src={tech.icon}
                      alt={tech.technology}
                      sx={{
                        width: 35,
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Card>
    </>
  );
}

