import { Grid, Typography, Link, Box } from "@mui/material";
import { Facebook, LinkedIn, Instagram } from "@mui/icons-material";
import { FOOTER } from "../../core/utils/contants/index";
import TogetherDevsLogo from "/logos/TogetherDevs.svg";

const FooterSection: React.FC = () => {
  return (
    <Box
      component="footer"
      sx={{
        pb: 2,
        color: "white",
        mx: { xs: 0, sm: "20%" },
      }}
    >
      <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Logo />
        </Grid>

        <Grid
          item
          xs={10}
          sm={10}
          md={10}
          lg={10}
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Grid
            item
            xs={6}
            sm={6}
            md={6}
            lg={6}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <Typography
              color="inherit"
              sx={{
                fontSize: { xs: 12, sm: 16 },
                fontFamily: "Raleway",
                mb: 1,
              }}
            >
              {FOOTER.FOLLOW_US}
            </Typography>

            <Grid
              item
              display="flex"
              flexDirection="row"
              alignItems="center"
              sx={{ m: 0.5 }}
            >
              <Link
                href="https://www.linkedin.com/company/togetherdevs/about/"
                color="inherit"
                target="_blank"
                rel="noopener noreferrer"
                sx={{ textDecoration: "none" }}
              >
                <Grid
                  item
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                >
                  <LinkedIn />
                  <Typography
                   
                    color="inherit"
                    sx={{
                      fontSize: { xs: 12, sm: 16 },
                      fontFamily: "Raleway",
                      ml: 1,
                    }}
                  >
                    {"LinkedIn"}
                  </Typography>
                </Grid>
              </Link>
            </Grid>

            <Grid
              item
              display="flex"
              flexDirection="row"
              alignItems="center"
              sx={{ m: 0.5 }}
            >
              <Link
                href="https://www.instagram.com/togetherdevs?igsh=cTdhcTU1aHkzdGp0"
                color="inherit"
                target="_blank"
                rel="noopener noreferrer"
                sx={{ ml: 1, textDecoration: "none" }}
              >
                <Grid
                  item
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                >
                  <Instagram />
                  <Typography
                   
                    color="inherit"
                    sx={{
                      fontSize: { xs: 12, sm: 16 },
                      fontFamily: "Raleway",
                      ml: 1,
                    }}
                  >
                    {"Instagram"}
                  </Typography>
                </Grid>
              </Link>
            </Grid>
            <Grid
              item
              display="flex"
              flexDirection="row"
              alignItems="center"
              sx={{ m: 0.5 }}
            >
              <Link
                href="https://www.facebook.com/Togetherdevs/"
                color="inherit"
                sx={{ ml: 1, textDecoration: "none" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Grid
                  item
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                >
                  <Facebook />
                  <Typography
                   
                    color="inherit"
                    sx={{
                      fontSize: { xs: 12, sm: 16 },
                      fontFamily: "Raleway",
                      ml: 1,
                    }}
                  >
                    {"Facebook"}
                  </Typography>
                </Grid>
              </Link>
            </Grid>
          </Grid>

          <Grid
            item
            xs={6}
            sm={6}
            md={6}
            lg={6}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            <Typography
             
              color="inherit"
              sx={{
                fontSize: { xs: 12, sm: 16 },
                fontFamily: "Raleway",
                mb: 1,
              }}
            >
              {"Locations:"}
            </Typography>
            {FOOTER.locations.map((location: string, index: number) => (
              <Typography
                key={index}
                variant="body2"
                color="inherit"
                sx={{
                  fontFamily: "Raleway",
                  textAlign: "left",
                  mb: 0.5,
                }}
              >
                {location}
              </Typography>
            ))}
          </Grid>
        </Grid>
      </Grid>

      <Typography
        variant="body2"
        color="inherit"
        sx={{
          mt: { xs: 4, sm: 1 },
          fontFamily: "Raleway",
          fontSize: { xs: 12, sm: 14 },
        }}
      >
        {FOOTER.ALL_RIGHTS_RESERVED}
      </Typography>
    </Box>
  );
};

const Logo = () => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    sx={{
      cursor: "pointer",
      maxWidth: { xs: "auto", sm: "auto", md: "auto" },
    }}
  >
    <Link
      className="flex items-center gap-1 text-current"
      href="https://togetherdevs.com"
      title="togetherdevs homepage"
      target="_blank"
      rel="noopener noreferrer"
    >
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <img
          src={TogetherDevsLogo}
          width={150}
          height="40px"
          alt="TogetherDevs"
        />
      </Grid>
    </Link>
  </Box>
);

export default FooterSection;
