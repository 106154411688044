import { Grid } from "@mui/material";
import CardCases from "../../components/Cards/CardCases/CardCases";
import ParalaxText from "../../components/Text/ParalaxText/ParalaxText";

export interface Technology {
  technology: string;
  icon: string;
  index: number;
}

export interface SuccesCasesItem {
  title: string;
  video: string;
  description: string;
  services: string[];
  technologies: Technology[];
}

export interface SuccesCasesProps {
  SuccesCases: SuccesCasesItem[];
}

export default function SuccesCasesSection({ SuccesCases }: SuccesCasesProps) {

  return (
    <>
      <Grid
        container
        sx={{
          mt: { xs: 2, sm: 1 },
          mx: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          direction: "column",
        }}
      >
        <ParalaxText
          phrase1={"Industries"}
          phrase2={"we work with"}
         
        />
        <Grid
          item
          xs={12}
          sx={{
            mt: { xs: 4, sm: 1 },
          }}
        ></Grid>
        {SuccesCases.map((p: SuccesCasesItem, index: number) => (
          <CardCases
            key={index}
            index={index}
            title={p.title}
            video={p.video}
            description={p.description}
            services={p.services}
            technologies={p.technologies}
          />
        ))}
      </Grid>
    </>
  );
}
