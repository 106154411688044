import { Grid, Typography } from '@mui/material';

const Porpouse = () => {
  return (
    <Grid item sx={{ mt:{ xs: 1, sm: 1 }, mb: 1 }}>
      <Typography
        variant="h1"
        component="h2"
        sx={{
          color: "#FFFFFF",
          fontFamily: "Raleway",
          fontWeight: 500,
          align: "center",
          fontSize: { xs: 30, sm: 40 },
        }}
      >
        {"We build Apps"}
      </Typography>
      <Typography
        variant="h1"
        component="h2"
        sx={{
          color: "#A0730C",
          fontFamily: "Raleway",
          fontWeight: 500,
          align: "center",
          fontSize: { xs: 30, sm: 40 },
        }}
      >
        {"creating digital experiences"}
      </Typography>
    </Grid>
  );
}

export default Porpouse

