import { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Collapse,
  Box,
  IconButton,
} from "@mui/material";
import AddTask from "@mui/icons-material/AddTask";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import theme from "../../../theme";

export interface CardProps {
  services: string[];
  title: string;
}

export default function CardHire({ services, title }: CardProps) {
  const [expanded, setExpanded] = useState(true);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{
        width: "100%",
      }}
    >
      <Card
        sx={{
          margin: { xs: "2%", sm: "3%", md: "5%" },
          padding: "2%",
          border: `2px solid ${theme.palette.primary.main}`,
          borderRadius: 2,
          cursor: "pointer",
          transition: "all 0.3s ease-in-out",
          background: "#000000",
          minWidth: { xs: "85vw", sm: "30vw", md: "18vw" },
          maxWidth: { xs: "300px", sm: "300px", md: "300px" },
        }}
        onClick={handleExpandClick}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ maxWidth: 300 }}
        >
          <Box display="flex" alignItems="center">
            <Typography
              color="#FFFFFF"
              variant="h5"
              component="div"
              fontSize={25}
              fontWeight={400}
              lineHeight={0.35}
              sx={{ pr: "6px", pl: "6px" }}
            >
              {"Hire"}
            </Typography>
            <Typography
              color="#FFFFFF"
              variant="h5"
              component="div"
              fontSize={25}
              fontWeight={600}
              lineHeight={0.35}
            >
              {title}
            </Typography>
          </Box>

          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="body2" color="#E8FE74" sx={{ ml: 1 }}>
              {expanded ? "Less" : "More"}
            </Typography>
            <IconButton
              onClick={handleExpandClick}
              aria-label={expanded ? "Collapse section" : "Expand section"}
              aria-expanded={expanded}
            >
              {expanded ? (
                <ExpandLessIcon sx={{ color: "#E8FE74" }} />
              ) : (
                <ExpandMoreIcon sx={{ color: "#E8FE74" }} />
              )}
            </IconButton>
          </Box>
        </Box>

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            {services.map((service, index) => (
              <Box
                key={index}
                display="flex"
                alignItems="center"
                sx={{ mb: 2 }}
              >
                {service === "Project Managment" && title === "our people" ? (
                  <HighlightOffIcon sx={{ mr: 1, color: "#FFFFFF" }} />
                ) : (
                  <AddTask sx={{ mr: 1, color: "#FFFFFF" }} />
                )}

                <Typography
                  variant="body2"
                  color="#FFFFFF"
                  sx={{ whiteSpace: "nowrap" }}
                >
                  {service}
                </Typography>
              </Box>
            ))}
            <Box display="flex" alignItems="center" sx={{ mt: 2 }}>
                <IconButton
              onClick={ () =>
        window.open("https://meetings.hubspot.com/david3299", "_blank")}
              aria-label={"Schedule a meeting"}
            >
              
              <CalendarMonthIcon sx={{ mr: 1, color: "#E8FE74" }} />
              <Typography variant="body2" color="#E8FE74">
                Schedule a meeting
              </Typography>
            </IconButton>
            </Box>
          </CardContent>
        </Collapse>
      </Card>
    </Box>
  );
}
