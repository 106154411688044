import { Grid, } from "@mui/material";
import CardHire from "../../components/Cards/CardHire/CardHire";
import ParalaxText from "../../components/Text/ParalaxText/ParalaxText";
import ActionButton from "../../components/Button/ContactButton/ContactButton";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { link_whatsapp } from "../../core/utils/contants";
import VisitButton from "../../components/Button/VisitButton/VisitButton";
import LanguageIcon from "@mui/icons-material/Language";

const ourTeam = {
  title: "our team",
  services: [
    "Technical Arquitecture Leadership",
    "Project Managment",
    "Warranty",
  ],
};

const ourPeople = {
  title: "our people",
  services: [
    "Flexible Technical Stack",
    "Project Managment",
    "Full time dedication",
  ],
};

export default function HireSection() {


  return (
    <>
      <Grid
        container
        sx={{
          mt: 1,
          mx: 0,
          background:
            "linear-gradient(0deg, #000000 0%, #000000 70%, #E8FE7490 100%)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          direction: "column",
          padding: { xs: "20px", sm: "40px" },
        }}
      >
        <Grid sx={{ mt: { xs: 2, sm: 4 } }}></Grid>
        <ParalaxText
          phrase1={"Ready to build"}
          phrase2={"the future Together?"}
          
        />
        <Grid item container sx={{ mt: 5 }}></Grid>

        <Grid
          container
          direction={{ xs: "column", sm: "row" }}
          spacing={{ xs: 2, sm: 4 }}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12} sm={6}>
            <CardHire services={ourTeam.services} title={ourTeam.title} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CardHire services={ourPeople.services} title={ourPeople.title} />
          </Grid>
        </Grid>

        <Grid
          container
          direction={{ xs: "column", sm: "row" }}
          spacing={{ xs: 1, sm: 4 }}
          justifyContent="center"
          alignItems="center"
          sx={{ mt: { xs: 2, sm: 4 } }}
        >
          <Grid item xs={12} sm={6}>
            <ActionButton
              label={"Schedule a Meeting"}
              icon={CalendarMonthIcon}
              onClick={() =>
                window.open("https://meetings.hubspot.com/david3299", "_blank")
              }
              color={"#FFFFFF"}
              hover={""}
              textColor={"#000000"}
              sx={{ width: "100%", height: 56 }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ActionButton
              label={"Contact Us"}
              icon={WhatsAppIcon}
              onClick={() => window.open(link_whatsapp, "_blank")}
              color={"#E8FE74"}
              hover={""}
              textColor={"#000000"}
              sx={{ width: "100%", height: 56 }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt:{xs:5}
            }}
          >
            <VisitButton
              label={"Visit Togetherdevs.com"}
              icon={LanguageIcon}
              textColor={"#FFF"}
              color={"#000000"}
              hover={""}
              onClick={() => window.open("https://togetherdevs.com/", "_blank")}
              gradientBorder="linear-gradient(90deg, #A0730C 0%, #E2C98F 100%)"
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
