import { Card, Grid } from "@mui/material";
import Porpouse from "../../components/Text/Porpouse/Porpouse";
//import group from "../../assets/videos/hero.mp4"
import group from "../../assets/videos/hero-fix.mp4"

interface HeroSectionProps {
  id: string;
}

const HeroSection: React.FC<HeroSectionProps> = ({ id }) => {
  return (
    <section id={id}>
      <Card
        className="no-border"
        sx={{
          padding: { xs: "1%", sm: "1%" },
          mt: { xs: "16%", sm: "10%", md: "4%" },
          mb: { xs: "10%", sm: "1%" },
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid
          container
          sx={{
            my: { xs: "5%", sm: "1%" },
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid
            sx={{
              width: {
                xs: "95vw",
                sm: "70vw",
                md: "50vw",
                lg: "40vw",
                xl: "40vw",
              },
              height: {
                xs: "vh",
                sm: "vh",
                md: "vh",
                lg: "vh",
                xl: "vh",
              },
              px: { xs: "0%", sm: "1%" },
              mb: { xs: "0.5%", sm: "0.5%" },
              overflow: "hidden",
              position: "relative",
            }}
          >

<video
        style={{
          minWidth: 300,
          minHeight: 450,
          width: "100%",
          height: "100%",
          objectFit: "cover",
          borderRadius: "5%"
        }}
        preload="auto"
        autoPlay
        playsInline
        loop
        muted
        onError={(e) => console.error("Error loading video", e)}
      >
        <source src={group} type="video/mp4" />
      </video>


          </Grid>
          <Porpouse />
        </Grid>
      </Card>
    </section>
  );
};

export default HeroSection;

