
export interface PlaceholderVideoProps {
  video: string;
}
;
function PlaceholderVideo({ video  }: PlaceholderVideoProps) {

  return (
    <div
      style={{
        width: "100%",
        minWidth: 300,
        minHeight: 450,
        borderRadius: 40,
        overflow: "hidden",
        background: "black",
        marginTop:"2%",
        marginBottom:"2%"
      }}
    >
      <video
        style={{
          minWidth: 300,
          minHeight: 450,
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
        preload="auto"
        autoPlay
        playsInline
        loop
        muted
        onError={(e) => console.error("Error loading video", e)}
      >
        <source src={video} type="video/mp4" />
      </video>
    </div>
  );
}

export default PlaceholderVideo;
