import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import { link_whatsapp } from "../../core/utils/contants";


import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DescriptionIcon from "@mui/icons-material/Description";
import LanguageIcon from "@mui/icons-material/Language";
import CloseIcon from "@mui/icons-material/Close";
import { Facebook, LinkedIn, Instagram } from "@mui/icons-material";

import TogetherDevsLogo from "/logos/TogetherDevs.svg";

interface MenuItem {
  text: string;
  icon: React.ReactNode;
  onClick: () => void;
}

const Navbar: React.FC = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open: boolean) => (): void => {
    setDrawerOpen(open);
  };

  const scrollToContact = () => {
  const contactSection = document.getElementById("Contact");
  if (contactSection) {
    contactSection.scrollIntoView({ behavior: "smooth" });
  }
};

const scrollToHero = () => {
  const HeroSection = document.getElementById("Hero");
  if (HeroSection) {
    HeroSection.scrollIntoView({ behavior: "smooth" });
  }
}; 

  const menuItems: MenuItem[] = [
    {
      text: "Schedule a Meeting",
      icon: <CalendarMonthIcon />,
      onClick: () =>
        window.open("https://meetings.hubspot.com/david3299", "_blank"),
    },
    {
      text: "Download Our Brochure",
      icon: <DescriptionIcon />,
      onClick: scrollToContact,
    },
    {
      text: "Chat with us",
      icon: <WhatsAppIcon />,
      onClick: () => window.open(link_whatsapp, "_blank"),
    },
    {
      text: "Visit Together Devs",
      icon: <LanguageIcon />,
      onClick: () => window.open("https://togetherdevs.com/", "_blank"),
    },
    {
      text: "LinkedIn",
      icon: <LinkedIn />,
      onClick: () =>
        window.open(
          "https://www.linkedin.com/company/togetherdevs/about/",
          "_blank"
        ),
    },
    {
      text: "Instagram",
      icon: <Instagram />,
      onClick: () =>
        window.open(
          "https://www.instagram.com/togetherdevs?igsh=cTdhcTU1aHkzdGp0",
          "_blank"
        ),
    },
    {
      text: "Facebook",
      icon: <Facebook />,
      onClick: () =>
        window.open("https://www.facebook.com/Togetherdevs/", "_blank"),
    },
  ];

  const drawer = (
    <Box sx={{ textAlign: "center" }}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ cursor: "pointer", padding: "16px", position: "relative" }}
      >
        <Box
          sx={{
            position: "absolute",
            left: 0,
            top: "50%",
            transform: "translateY(-50%)",
            cursor: "pointer",
            color: "white",
            padding: "16px",
          }}
        >
          <CloseIcon onClick={toggleDrawer(false)} />
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ cursor: "pointer", pt: "16px"}}
        >
          <img
            src={TogetherDevsLogo}
            width={"100%"}
            height={"100%"}
            alt="TogetherDevs"
            style={{ minHeight: "40px", maxHeight: "55px", maxWidth: "160px" }}
          />
        </Box>
      </Box>
      <List onClick={toggleDrawer(false)}>
        {menuItems.map((item, index) => (
          <ListItemButton
            key={index}
            onClick={item.onClick}
            sx={{ color: "white", py: "5px", mx: 4 }}
          >
            {item.icon && (
              <Box component="span" sx={{ marginRight: 2 }}>
                {item.icon}
              </Box>
            )}
            <ListItemText primary={item.text} />
          </ListItemButton>
        ))}
      </List>
    </Box>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="fixed"
        sx={{ backgroundColor: "black", height: "60px" }}
      >
        <Toolbar sx={{ height: "100%", minHeight: "unset" }}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Box
            sx={{
              flexGrow: { xs: 1, sm: 2 },
              display: { xs: "block", sm: "block" },
            }}
          />
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexGrow={1}
            sx={{ cursor: "pointer" }}
            my={1}
            onClick={scrollToHero}
            
          >
            <img src={TogetherDevsLogo} height="40px" width={"100%"} alt="TogetherDevs" />
          </Box>
          <Box sx={{ flexGrow: { xs: 1, sm: 2.2 }, display: { xs: "block", sm: "block" } }} />
        </Toolbar>
      </AppBar>

      <Drawer
        variant="temporary"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: "block",
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: { xs: "100%", sm: "35%" },
            backgroundColor: "black",
            overflow: "hidden",
          },
        }}
      >
        {drawer}
      </Drawer>
    </Box>
  );
};

export default Navbar;
