import React, { useState, Suspense } from 'react';
import { Dialog, Grid, TextField, Typography } from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import theme from '../../theme';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RESPONSES } from '../../core/utils/contants';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useInView } from 'react-intersection-observer';
import { useForm, Controller } from 'react-hook-form';

const ActionButton = React.lazy(() => import('../../components/Button/ContactButton/ContactButton'));
const ModalBrocure = React.lazy(() => import('../../components/Cards/ModalBrocure/ModalBrochure'));

interface ContactSectionProps {
  id: string;
}

const yupSchema = yup
  .object({
    firstName: yup.string().required('First name is required'),
    lastName: yup.string().required('Last name is required'),
    phone: yup.string(),
    email: yup
      .string()
      .email('Enter a valid email')
      .required('Email is required'),
  })
  .required();

const ContactSection: React.FC<ContactSectionProps> = ({ id }) => {
  const [open, setOpen] = useState(false);
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.1 });

  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(yupSchema),
    mode: 'onBlur',
    defaultValues: {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
    },
  });

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async (data: any) => {
    const response = await fetch(
      `https://api.hsforms.com/submissions/v3/integration/submit/24068318/0c08869a-4a02-4069-af24-a2493977c3ad`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          fields: [
            { name: 'firstname', value: data.firstName },
            { name: 'lastname', value: data.lastName },
            { name: 'phone_number', value: data.phone },
            { name: 'email', value: data.email },
          ],
          context: {
            pageUri: window.location.href,
            pageName: document.title,
          },
        }),
      }
    );

    if (response.ok) {
      toast.success(RESPONSES.SUCCESS);
      reset();
      const newWindow = window.open('https://hubs.ly/Q02Lqqw_0', '_blank');
      if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
        setOpen(true);
      }
    } else {
      toast.error(RESPONSES.FAILED);
    }
  };

  return (
    <section id={id} ref={ref}>
      {inView && (
        <Suspense fallback={<div>Loading...</div>}>
          <Grid
            container
            sx={{
              my: 4,
              mx: 'auto',
              background: 'linear-gradient(0deg, #A0730C 30%, #E8FE74 100%)',
              display: 'flex',
              flexDirection: 'column',
              alignItems: { xs: 'left', sm: 'center', md: 'center' },
              px: { xs: 2, md: 4 },
              py: 8,
              borderRadius: 2,
            }}
          >
            <Grid
              container
              direction='column'
              sx={{
                width: '100%',
                maxWidth: 400,
              }}
            >
              <Grid item>
                <Typography
                  fontFamily={'Raleway'}
                  fontSize={{ xs: 40, md: 50 }}
                  color={'#06050F'}
                  sx={{
                    fontWeight: 400,
                    whiteSpace: 'normal',
                    textAlign: { xs: 'left', md: 'center' },
                    opacity: 0.5,
                  }}
                >
                  {'Want to'}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  fontFamily={'Raleway'}
                  fontSize={{ xs: 40, md: 50 }}
                  color={'#06050F'}
                  sx={{
                    fontWeight: 400,
                    whiteSpace: 'normal',
                    textAlign: { xs: 'left', md: 'center' },
                  }}
                >
                  {'know more?'}
                </Typography>
              </Grid>
            </Grid>

            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid
                container
                direction='column'
                spacing={2}
                sx={{
                  mt: 2,
                  width: '100%',
                  maxWidth: 500,
                }}
              >
                <Grid item>
                  <Controller
                    name='firstName'
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label='First Name'
                        variant='outlined'
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        InputLabelProps={{
                          style: { fontFamily: 'Raleway', color: '#364049' },
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid item>
                  <Controller
                    name='lastName'
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label='Last Name'
                        variant='outlined'
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        InputLabelProps={{
                          style: { fontFamily: 'Raleway', color: '#364049' },
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid item>
                  <Controller
                    name='email'
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label='Email'
                        variant='outlined'
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        InputLabelProps={{
                          style: { fontFamily: 'Raleway', color: '#364049' },
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid item>
                  <Controller
                    name='phone'
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label='Phone number'
                        variant='outlined'
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        InputLabelProps={{
                          style: { fontFamily: 'Raleway', color: '#364049' },
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid item sx={{ mt: 2, maxWidth: 500 }}>
                  <ActionButton
                    label={'Download Our Brochure'}
                    icon={CloudDownloadIcon}
                    color={theme.palette.background.default}
                    hover={''}
                    textColor={theme.palette.secondary.main}
                    sx={{ width: '100%', height: 56, maxWidth: 400 }}
                    onClick={handleSubmit(onSubmit)}
                  />
                </Grid>
              </Grid>
            </form>
          </Grid>

          <ToastContainer
            position='bottom-right'
            autoClose={6000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme='light'
          />

          <Dialog
            open={open}
            onClose={handleClose}
            sx={{
              "& .MuiDialog-paper": {
                backgroundColor: "transparent",
                borderRadius: "15px",
                overflow: "hidden",
                width: "85%",
                maxWidth: { xs: "95%", sm: "45%", md: "35%", lg: "25%", xl: "20%" },
                position: "relative",
                maxHeight: { xs: "50vh", sm: "400px" },
                minHeight: 50,
                boxShadow: "none",
                border: "solid 2px #A0730C",
              },
            }}
          >
            <ModalBrocure onClick={handleClose} />
          </Dialog>
        </Suspense>
      )}
    </section>
  );
};

export default ContactSection;

